<template>
  <div id="app">
    <router-view />
    <!-- <Loading v-if="vloading"></Loading> -->
    <!-- <Dialog v-model="vshow" @change="onClose"></Dialog> -->
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
// import Dialog from "@/components/dialog.vue";

export default {
  data() {
    return {
      vshow: true,
    };
  },
  components: {
    // Loading,
    // Dialog,
  },
  created() {
    this.$nextTick(() => {
    });
  },
  methods: {
    onClose(e) {
      console.log(e, this.vshow);
    },
    onClick() {},
    setData() {
      // this.$store.dispatch("action_updateApp", {
      //   type: "discoverList",
      //   query: {},
      // });
    },
    handleCustomEvent() {
     
    },
  },
  computed: {
    vloading() {
      return this.$store.getters.GET_LOGING;
    },
    trMoney() {
      return this.money.toLocaleString();
    },
    // config(){
    //   return this.$store.getters.GET_CONFIGLIST
    // }
  },

};
</script>

<style lang="scss" scoped>
// #app{
//   width: $vw;
//   height: $vh;
//   @include min{
//     width: 750px;
//   }
// }

</style>
